import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    
    
    static values = { profileId: { type: String, default: '0' } }


    registerClick() {
 
      //Testing that click is registered
      //console.log("linkToClick: ",this.element.href)
      
      //Added by Sune 23 Oct 2022 to handle case where no no CSRF token is avilable
      var csrfToken = '';
      let token = document.querySelector('meta[name="csrf-token"]');
        if (token) {
                csrfToken = token.content;
                console.log('CSRF token set');
          
        }

      //Get csfr token as per https://akshaykhot.com/building-to-do-list-using-hotwire-and-stimulus/
      //const csrfToken = document.querySelector("[name='csrf-token']").content


      // Create the link to edit Profiles Model
      let stringURL = location.protocol + '//' + location.host + '/profiles/' + String(this.profileIdValue) +'/set_forwarding_link_clicked'
    //   console.log("stringurl: ",stringURL.toString())
    //   console.log("location", location.protocol)
    //   console.log("host", location.host)
    

      let url = new URL(stringURL)
    //   console.log("url: ",url.toString())
      
      
      let formData = new FormData()
      formData.append("profile[forwarding_link_clicked]", true); //This is aded to avoid bad request content body mesage
      //formData.append("profile[id]", this.profileIdValue);
      
      fetch(url, {
        method: 'POST',
        credentials: "include",
        dataType: "script",
        headers: {
                "X-CSRF-Token": csrfToken
         },
      })
      
      
      
      

      //set Next Button to active
      var nextButton = document.getElementById('nextButton');
      //console.log("nextButton: ",nextButton);
      nextButton.disabled = false;



    }

}

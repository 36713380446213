// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  static targets = [ "input" ]

    connect() {
        const input = this.inputTarget;
        intlTelInput(input, {
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js",
            hiddenInput: "phone",
            initialCountry: "my",
            preferredCountries: ["dk", "sg", "id"],
            separateDialCode: true,
            autoPlaceholder: "aggressive",
            onlyCountries: ["dk", "lv","li", "lt", "lu", "mk", "my", "sg"],
        });
    }
  }
